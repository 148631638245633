import { createHashRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import Root from './components/Root';
import Header from './components/Header';
import About from './components/About';
import Contact from './components/Contact';

function App() {

  const router = createHashRouter(
    createRoutesFromElements(
        <Route path='/' element={<Root />}>
          <Route index element={<Header />} />
          <Route path='/about' element={<About />} />
          <Route path='/contact' element={<Contact />} />
        </Route>
    )
  )

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
