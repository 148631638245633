import React from 'react';
import { Link } from 'react-router-dom';
import { Zoom } from 'react-awesome-reveal';
import logo from '../logo3.jpg';
import Services from './Services';
import Footer from './Footer';

const Header = () => {
    return(
        <div>
        <div className="container" id="header-glass">
            <Zoom>
                <div className="row" id='header-r'>
                    <div className="col-sm-6">
                        <img src={ logo } alt="logo" className='logo'/>
                        <h1>JM Web Solutions</h1>
                        <p>We are your one-stop shop for all your website needs. <br />
                        Having an online prescence in the digital age is <br />a neccessity, whether 
                        you are an individual brand or a company.</p>
                    </div>
                </div>
            </Zoom>
            <Zoom>
                <div className="row" id='frow'>
                    <div className="col-sm-3" id='glass-col'>
                        <i id="featureicon" className="fa fa-puzzle-piece bigger"></i>
                        <h5>SEO Optimized websites</h5>
                        <p>Search Engine Optimization(SEO) is an important function that 
                            affects how search engines(mainly Google) rank your website.
                            Poorly designed sites that do not adhere to best SEO practices 
                            will adversely affect the amount of traffic that flows to your website. 
                            We are strongly rooting for you and your business, and therefore strive 
                            to maximize the amount of traffic to your website.</p>
                    </div>
                    <div className="col-sm-4" id='glass-col'>
                        <i id="featureicon" className="fa fa-thumbs-up bigger"></i>
                        <h5>Affordable Prices</h5>
                        <p>Our products have different prices depending on the type, complexity, 
                            and time-to-market(TTM). However, we're 
                            always ready to sit down with our clients and design 
                            solutions that fit their budgets.</p>
                        <Link to='/contact' className='btn btn-primary'>request quotation</Link>
                    </div>
                    <div className="col-sm-3" id='glass-col'>
                        <i id="featureicon" className="fa fa-cogs bigger"></i>
                        <h5>Custom Design and Functionality</h5>
                        <p>At JM web solutions, we do not just create the "standard" and 
                            "boring" websites. We believe in offering value to your business, and 
                            therefore strive to engineer custom digital solutions that fulfill your 
                            specific business needs.</p>
                    </div>
                </div>
            </Zoom>
        </div>
        <Services />
        <Footer />
        </div>
    );
}

export default Header;
