import React from 'react';
import { Link } from 'react-router-dom';
import { Zoom, Slide } from 'react-awesome-reveal';
import img8 from '../image/img-8.jpg';
import img5 from '../image/img-5.jpg';
import img9 from '../image/img-9.jpg';
import portfolio from '../image/portfolios.jpg';
import landing from '../image/landing.jpg';
import corporate from '../image/corporate.jpg'

const Services = () => {
    return(
        <div>
            <div className="container" id="glass">
            <Slide>
                <div className="row" id="products">
                    <div className="col-sm-5">
                        <h3>Our Products/services</h3>
                    </div>
                    <div className="col-sm-7">
                        <ul>
                            <li>Portfolio websites</li>
                            <li>Corporate websites</li>
                            <li>Landing Pages</li>
                            <li>Business Emails</li>
                            <li>Blogging sites</li>
                        </ul>
                    </div>
                </div>
            </Slide>
            </div>
            <div className="container" id="glass">
                <Zoom>
                    <div className="card">
                        <div className="row">
                            <div className="col-sm-4">
                                <img src={portfolio} alt="portfolio sites" id='card-img'/>
                            </div>
                            <div className="col-sm-6">
                                <h4>Portfolio Websites</h4>
                                <p>Portfolio sites help you showcase your projects, achievements and 
                                    skills to the world or to your target audience. Whether you are 
                                    a designer, developer, artist, or writer, portfolio websites are
                                        an excellent way of showing the world what you can do.</p>
                                <p>Are you a graduate actively looking for opportunities? You can combine 
                                    that LinkedIn profile with a beautifully designed portfolio to form
                                    a formidable selling point for your skills and projects.</p>
                            </div>
                        </div>
                    </div>
                </Zoom>
                <Slide>
                <div className="card">
                    <div className="row">
                        <div className="col-sm-4">
                            <img src={corporate} alt="company sites" id='card-img'/>
                        </div>
                        <div className="col-sm-6">
                            <h4>Corporate Websites</h4>
                            <p>A professional website is a necessity for most businesses, from 
                                startups to medium and big multi-national organizations. A corporate 
                                website is usually the first point of contact between the business and 
                                remote clients. As an organization, you need one to augment your brand 
                                by showcasing your services, beliefs, mission, and vision statements. </p>
                        </div>
                    </div>
                </div>
                </Slide>
                <Zoom>
                <div className="card">
                    <div className="row">
                        <div className="col-sm-4">
                            <img src={ img8 } alt="business emails" id='card-img' />
                        </div>
                        <div className="col-sm-6">
                            <h4>Business Emails</h4>
                            <p>Emails can be categorized into two namely: Business(paid) and free emails. 
                                Google Mail(@gmail.com) accounts are free to create and use, 
                                and for most people, the convenience they offer is enough. However, 
                                if you are a business owner or an organization, a custom and professionally 
                                looking email address is a big boost to your operational efficiency and 
                                brand image. An example of a business email would be "yourname@yourdomain.com" 
                                or "yourname@yourdomain.org".</p>
                            <p>Business emails have several advantages over free emails including:</p>
                            <ul>
                                <li>Expansive cloud storage.</li>
                                <li>Better security.</li>
                                <li>More convenient for bulk emails.</li>
                                <li>Email filtering, tracking, and customized auto-responders.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                </Zoom>
            </div>
            <div className="container" id="glass">
                <Zoom>
                    <div className="card">
                        <div className="row">
                            <div className="col-sm-4">
                                <img src={landing} alt="landing pages" id='card-img'/>
                            </div>
                            <div className="col-sm-6">
                                <h4>Landing Pages</h4>
                                <p>In the digital marketing landscape, a landing page might be one of 
                                    the most important tools for generating leads. You might be wondering, what is 
                                    a landing page? How does it differ from a website? Well, a website is a collection
                                    of different web pages connected through links, while a landing page is a single 
                                    page whose sole intent is to convince users to take an action. These actions might 
                                    vary from persuading users to subscribe to email newsletters to registering new 
                                    members to a conference. </p>
                                <p>A landing page is a powerful sales tool for your business. Contact us through the button 
                                    below for more information on how you can leverage one to convert traffic into sales.</p>
                                <Link to='/contact' className='btn btn-primary'>reach out</Link>
                            </div>
                        </div>
                    </div>
                </Zoom>
                <Zoom>
                <div className="card">
                    <div className="row">
                        <div className="col-sm-4">
                            <img src={ img5 } alt="website re-design" />
                        </div>
                        <div className="col-sm-6">
                            <h4>Website Re-design</h4>
                            <p>Perhaps you have an old and outdated website that you need re-designed, 
                                or maybe you have an amazing site that does not match your evolving 
                                business needs. Worry no more! We will give your website a complete 
                                makeover and add extra functionality to suit your operational needs. We 
                                also optimize your website for faster perfomance and better user experience.</p>
                        </div>
                    </div>
                </div>
                </Zoom>
                <Slide>
                    <div className="card">
                        <div className="row">
                            <div className="col-sm-4">
                                <img src={ img9 } alt="blogging sites" />
                            </div>
                            <div className="col-sm-6">
                                <h4>Blogging sites</h4>
                                <p>Are you a passionate content writer or just starting out? 
                                    We will create a blogging platform just for you so that you 
                                    can express and share your thoughts and opinions with the rest of the world.</p>
                            </div>
                        </div>
                    </div>
                </Slide>
            </div>
        </div>
    ); 
}

export default Services;