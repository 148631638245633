import React from 'react';
import { Link, Outlet } from 'react-router-dom';

const Root = () => {
    return(
        <div>
        <div className="container" id="nav-r">
            <div className="navbar navbar-expand-lg">
                <div className="navbar-brand">
                    <Link to='/'>
                        <i className="fas fa-home"></i>
                    </Link>
                </div>
                <button className="navbar-toggler" type='btn' data-bs-toggle="collapse" data-bs-target="#mynav">
                    <i className="fas fa-bars"></i>
                </button>
                <div className="collapse navbar-collapse" id="mynav">
                    <div className="navbar-nav">
                        <Link to="/about" className="nav-item nav-link">about</Link>
                        <Link to="/contact" className="btn btn-root" id='contact'>contact us</Link>
                    </div>
                </div>
            </div>
        </div>
        <Outlet />
        </div>
    );
}

export default Root;