import React from 'react';
import { Zoom, Slide } from 'react-awesome-reveal';

export default function About() {
    return(
        <div className="container" id="glass">
            <Zoom>
                <div className="row" id='row'>
                    <div className="col-sm-5">
                        <h4>About Us</h4>
                        <p>JMwebsolutions.com is a Kenyan-based web development and web solutions 
                        provider that focuses on delivering quality and 
                        custom products to businesses and individuals. We 
                        don't just create software for the web, but tailor-made 
                        web products based on your needs. That's why we strongly 
                        believe in a business-first approach, whereby we analyze your 
                        business's operations and design systems to make your work easier, 
                        promote sales and/or increase efficiency.</p>
                    </div>
                </div>
            </Zoom>
            <Slide>
                <div className="row" id='row'>
                    <div className="col-sm-4">
                        <h4>Our core values </h4>
                        <p>Integrity, Honesty, Dilligence, Quality service</p>
                    </div>
                    <div className="col-sm-4">
                        <h4>Our Mission </h4>
                        <p>To contribute towards the success of our client's 
                            businesses through our quality and tailor-made 
                            products and services.</p>
                    </div>
                    <div className="col-sm-4">
                        <h4>Our Vision </h4>
                        <p>To create efficiency and transparency for the growth of of businesses and business owners.</p>
                    </div>
                </div>
            </Slide>
        </div>
    );
}