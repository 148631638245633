import React, { useState } from 'react';
import axios from 'axios';

function Contact() {

    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [serverResponse, setServerResponse] = useState('');

    function reset() {
        setEmail("")
        setMessage("")
    }

    function handleFormSubmit(e) {
        e.preventDefault();
        const load = {email, message}
        const url = 'https://www.jmwebsolutions.com/api/client-mail';
        axios.post(url, {load})
            .then((res) => {
                setServerResponse(res.data);
            })
            .catch((error) => {console.log(error.message);})
        reset();
    }

    return(
        <div className="container" id='glass'>
            <div className="row">
                <div className="col-sm-5">
                    <h3>Send us a message</h3>
                    <br />
                    <form onSubmit={handleFormSubmit}>
                        <div className="form-group">
                            <label htmlFor="email">Your email</label>
                            <input type="text"
                                className='form-control'
                                id='email'
                                value={email} 
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder='e.g. example@gmail.com' required/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="msg">Your message/inquiry</label>
                            <textarea id='msg' className='form-control' rows="3" value={message} onChange={(e) => setMessage(e.target.value)} required></textarea>
                        </div>
                        <br />
                        <div className="alert-success">
                            <p>{serverResponse}</p>
                        </div>
                        <button className='btn btn-primary' type='submit'>send</button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Contact;