import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Footer extends Component {
    render() {
        return(
            <div>
                <div className="container" id="glass">
                    <div className="row" id='products'>
                        <div className="col-sm-3">
                            <Link to='https://wa.me/254702295794'>
                                <i className="fab fa-whatsapp" id='wa-icon'></i>
                            </Link>
                            <p>+254 702 295 794</p>
                            <p>+254 719 120 848</p>
                            <i className="fas fa-envelope" id='e-icon'></i>
                            <p>Email: info@jmwebsolutions.com</p>
                        </div>
                        <div className="col-sm-4">
                            <h3>our services</h3>
                            <ul>
                                <li>Portfolio sites</li>
                                <li>Company websites</li>
                                <li>Landing pages</li>
                                <li>Business emails</li>
                                <li>Blogging sites</li>
                            </ul>
                        </div>
                        <div className="col-sm-3">
                            <Link to='about'>who we are</Link><br />
                            <br />
                            <Link to='contact' className='btn btn-success'>reach out</Link>
                            <br />
                            <br /> 
                            <p>Copyright&copy;2023 jmwebsolutions.com</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;